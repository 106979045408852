
































import Vue from 'vue'
import moment from 'moment/moment'
import PromotionDialog from '@/layouts/content-banner/PromotionDialog.vue'

export default Vue.extend({
  name: 'ContentBanner',
  components: { PromotionDialog },
  data() {
    return {
      startDate: '2023-12-27 00:01',
      endDate: '2024-01-05 23:59',
      popupStartDate: '2023-12-27 00:01',
      popupEndDate: '2024-01-05 23:59',
    }
  },

  computed: {
    showPromoBanner() {
      return moment().isBetween(this.startDate, this.endDate)
    },
    showPopup() {
      return moment().isBetween(this.popupStartDate, this.popupEndDate)
    },
    showTechnicalBanner() {
      return this.$route.meta.banner?.active
    },
    showServiceBanner() {
      return false

      // return moment().isBetween('2023-11-11 00:01', '2024-01-02 23:59')
    },
  },

  // Для нескольких баннеров
  // mounted() {
  //   setInterval(() => {
  //     this.currentBanner++
  //   }, 5000)
  // },
})
